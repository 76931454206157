import { DateTime } from "luxon"

export const millisToHuman = (millis, config = {  month: 'long', day: 'numeric' }) => {
  if (typeof millis !== 'number') return ''

  return DateTime.fromMillis(millis * 1000)
    .setLocale('pt-BR')
    .toLocaleString(config)
}

export default {
  millisToHuman
}