import React from 'react'
import { useHistory } from 'react-router-dom'
import {startsWithHttp} from 'helpers/strings'
import get from 'lodash.get'

// Style
import './LinkInternal.scss'

function LinkInternal(props) {
  const history = useHistory()
  const color = get(props, 'color')
  const className = get(props, 'className')
  const title = get(props, 'title')
  const onClick = get(props, 'onClick')
  const url = get(props, 'url')
  const icon = get(props, 'icon')
  const iconAlign = get(props, 'iconAlign')

  return (
    <button
      className={`LinkInternal typecolor-${color} link-internal ${className || ''}`}
      title={title}
      onClick={() => onClick ? onClick() : history.push(`${url}`)}
    >
      {icon && iconAlign === "left" && (
        <i
          className={
            startsWithHttp(url)
              ? "mr-2 icone-diagonal-arrow-left-up"
              : `mr-2 icone-${icon}`
          }
        />
      )}

      {title}

      {icon && iconAlign === "right" && (
        <i
          className={
            startsWithHttp(url)
              ? "ml-2 icone-diagonal-arrow-right-up"
              : `ml-2 icone-${icon}`
          }
        />
      )}
    </button>
  );
}

export default LinkInternal