import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import classnames from 'classnames'
import Slider from 'react-slick'
import { get } from 'lodash'
import LinkInternal from 'components/atoms/LinkInternal'
import Typography from 'components/atoms/Typography'
import CardNews from 'components/molecules/CardNews'
import SectionTitle from 'components/molecules/SectionTitle'
import { millisToHuman } from 'helpers/dates'
import { userCheckMobileScreen } from 'helpers/tools'

// styles
import './LatestNews.scss'
import 'slick-carousel/slick/slick.css'

function LatestNews({ data }) {
  const isMobile = userCheckMobileScreen()
  const sectionTitle = get(data, 'sectionTitle')
  const id = get(data, 'id')
  const title = get(data, 'title')
  const themeClasses = get(data, 'theme.entity.classes')
  const sliderSettings = {
    dots: true,
    dotsClass: `slick-dots primary`,
    centerPadding: '20px',
    arrows: false,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
        },
      },
    ],
  }
  const items = get(data, 'news.items')

  return (
    <Container id={id} className={classnames('latest-news py-3 py-lg-5', themeClasses)} fluid="fluid">
      <Container>
        <Row>
          {sectionTitle && (
            <Col sm={12}>
              <SectionTitle title={sectionTitle} className="mb-4" />
            </Col>
          )}
          {title && (
            <Col sm={12} md={6}>
              <Typography variant="h2" cssClass="latest-news__title title-semibold-md">
                {title}
              </Typography>
            </Col>
          )}
          {get(data, 'link.url.path') && (
            <Col sm={12} md={6} className="d-flex justify-content-md-end">
              <LinkInternal
                title={get(data, 'link.title')}
                url={get(data, 'link.url.path')}
                icon="arrow-right"
                className="latest-news__link"
                iconAlign="right"
              />
            </Col>
          )}
        </Row>

        {Array.isArray(items) &&
          (get(data, 'carouselMobile') ? (
            <Row className="py-4">
              <Col sm={12} md={12}>
                <Slider {...sliderSettings} className="padding-slider">
                  {items?.map((item) => (
                    <CardNews
                      alt={get(item, 'media.entity.images.alt')}
                      key={get(item, 'created')}
                      url={get(item, 'url.path')}
                      image={
                        isMobile
                          ? get(item, 'media.entity.images.medium.url')
                          : get(item, 'media.entity.images.wide.url')
                      }
                      date={millisToHuman(get(item, 'created'))}
                      title={get(item, 'title')}
                      preview={get(item, 'body.summary')}
                    />
                  ))}
                </Slider>
              </Col>
            </Row>
          ) : (
            <Row className="pt-4">
              {items?.map((item) => (
                <Col className="mb-5" sm={12} md={4} key={get(item, 'created')}>
                  <CardNews
                    alt={get(item, 'media.entity.images.alt')}
                    date={millisToHuman(get(item, 'created'))}
                    url={get(item, 'url.path')}
                    image={
                      isMobile ? get(item, 'media.entity.images.medium.url') : get(item, 'media.entity.images.wide.url')
                    }
                    title={get(item, 'title')}
                    preview={get(item, 'body.summary')}
                  />
                </Col>
              ))}
            </Row>
          ))}
      </Container>
    </Container>
  )
}

export default LatestNews
