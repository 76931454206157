import React from 'react'
import { HashLink } from 'react-router-hash-link'
import classnames from 'classnames'

import './CardNews.scss'

function CardNews({ alt, title, url, image, date, preview, highlight, reduced }) {
  return (
    <HashLink to={url || '#'} title={title || ''}>
      <div className={classnames('card-news', { highlight, reduced })}>
        {image && <img src={image} alt={alt} />}
        {date && <p className="card-news-date">{date}</p>}
        {title && <h3 className="max-line-3 card-news-title">{title}</h3>}
        {preview && <p className="max-line-4 card-news-preview">{preview}</p>}
      </div>
    </HashLink>
  )
}

export default CardNews
